<template>
  <form
    class="Form"
    novalidate
    @submit.prevent="onSubmit()"
  >
    <div
      class="Form-Fields"
      :class="{ 'Form-Fields--rows': isRows }"
    >
      <div class="Form-Field">
        <label for="telegram" class="Form-Label">
          <span class="Form-LabelSpan">{{ $t('form.telegram.label') }}</span>
          <img :src="require(`@/assets/images/red-star.svg`)" alt="" class="Form-LabelImg">
        </label>
        <input type="text" id="telegram"
                autocomplete="off"
                v-model.trim="form.telegram"
                class="Input Form-Input"
                :placeholder="$t('form.telegram.placeholder')"
                :class="$v.form.telegram.$dirty && !$v.form.telegram.required ? 'is-invalid animate__animated animate__shakeX' : ''"
        >
      </div>
      <div class="Form-Field">
        <label for="message" class="Form-Label">
          {{ $t('form.message.label') }}
        </label>
        <textarea id="message"
                  class="Textarea Form-Input"
                  v-model.trim="form.message"
                  autocomplete="off"
                  :placeholder="$t('form.message.placeholder')"></textarea>
      </div>
    </div>

    <div class="Form-Submit">
      <button type="submit" class="Form-SubmitButton Button">
        <span class="Button-Text">
          {{ $t('form.button') }}
        </span>
        <span class="Button-Shadow"></span>
      </button>
    </div>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import {showModal} from "../assets/utils/utils";

export default {
  name: "Form",
  props: {
    isRows: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        telegram: '',
        message: '',
      },
    }
  },
  validations: {
    form: {
      telegram: {
        required
      },
      message: {},
    },
  },
  methods: {
    showModal,
    onSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {

        const token = '1817984401:AAFHuROfIEIZcxLFgTZeYNO4EEhjdd4Dd2Y';
        const chatId = '-1001286048560';
        const formData = this.form
        const formattedFormData = Object.entries(formData).map(entity => `${(entity[0])}: ${entity[1]} |`).join(' ')

        axios.get(
            `https://api.telegram.org/bot${token}/sendMessage?text=${formattedFormData}&chat_id=${chatId}`,

            {
              headers: {
                'content-type': 'application/json'
              }
            }
        ).then(() => {

        })
        this.showModal('formsent')
        this.form.telegram = ''
        this.form.message = ''
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.Form {
  &-Fields {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px 0;
    margin-bottom: 32px;

    @media (min-width: $screen-l) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px 127px;
      margin-bottom: 54px;
    }

    &--rows {
      display: block;
    }    
  }

  &-Field {
    display: flex;
    flex-direction: column;

    .Form-Fields--rows & {
      margin-bottom: 15px;

      @media (min-width: $screen-l) {
        margin-bottom: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-Label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
    padding-left: 1px;
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
    color: var(--color-text-label);
  }

  &-Input {
    outline: none;
    border-top: 1px solid var(--color-body);
    border-right: 1px solid var(--color-body);
    border-bottom: 1px solid var(--color-input-border);
    border-left: 1px solid var(--color-body);
    background-color: var(--color-body);
    font-family: 'JetBrainsMono', monospace;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: var(--color-text-main);

    &::placeholder {
      font-weight: 400 !important;
      font-size: 16px;
      line-height: 1.3;
      color: var(--color-text-label);
    }
  }

  &-Submit {
    text-align: center;

    @media (min-width: $screen-l) {
      text-align: right;
    }
  }

  .Input {
    padding: 7px 12px 12px 0;
    transition: .3s;
  }

  .Textarea {
    resize: none;
    height: 41px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .Disabled {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Button {
    width: 100%;

    @media (min-width: $screen-l) {
      display: inline-block;
      width: 312px;
    }
  }
}

.is-invalid {
  border-bottom: 1px solid var(--color-error);
  transition: .3s;
}
</style>
